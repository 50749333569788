import { gql } from '@apollo/client';

export const GET_PROPERTIES = gql`
  query GET_PROPERTIES($limit: Int, $filter: FilterFindManyPropertyInput) {
    properties(limit: $limit, filter: $filter) {
      _id
      universalPropertyId
      universalPropertySubId
      propertyType
      active
      propertySubType
      businessType
      features
      currentUse
      fencing
      laundryFeatures
      lotFeatures
      lotSizeArea
      lotSizeDimensions
      lotSizeUnits
      numberOfUnitsTotal
      parkName
      poolFeatures
      poolPrivateYN
      possibleUse
      seniorCommunityYN
      spaFeatures
      topography
      view
      waterBodyName
      waterfrontYN
      appliances
      rentIncludes
      associationAmenities
      associationFee
      associationFeeFrequency
      associationFeeIncludes
      associationYN
      accessibilityFeatures
      architecturalStyle
      attachedGarageYN
      basement
      bathroomsFull
      bathroomsHalf
      bathroomsOneQuarter
      bathroomsPartial
      bathroomsThreeQuarter
      bathroomsTotalInteger
      bedroomsTotal
      bodyType
      photos
      carportSpaces
      constructionMaterials
      cooling
      coolingYN
      directionFaces
      exteriorFeatures
      fireplaceFeatures
      fireplaceYN
      fireplacesTotal
      flooring
      foundationDetails
      garageSpaces
      heating
      heatingYN
      interiorFeatures
      levels
      livingArea
      livingAreaSource
      livingAreaUnits
      mobileLength
      mobileWidth
      newConstructionYN
      parkingFeatures
      patioAndPorchFeatures
      propertyAttachedYN
      roof
      skirt
      stories
      storiesTotal
      windowFeatures
      yearBuilt
      location {
        address {
          streetName
          city
          stateOrProvince
          postalCode
        }
        GIS {
          latitude
          longitude
          directions
        }
      }
      listing {
        publicRemarks
        closePrice
        listPrice
        mlsStatus
      }
    }
  }
`;

export const GET_PROPERTIES_FILTER = gql`
  query GET_PROPERTIES_FILTER($data: PaginationApi) {
    propertiesFilter(data: $data) {
      _id
      universalPropertyId
      universalPropertySubId
      propertyType
      active
      propertySubType
      businessType
      features
      currentUse
      fencing
      laundryFeatures
      lotFeatures
      lotSizeArea
      lotSizeDimensions
      lotSizeUnits
      numberOfUnitsTotal
      parkName
      poolFeatures
      poolPrivateYN
      possibleUse
      seniorCommunityYN
      spaFeatures
      topography
      view
      waterBodyName
      waterfrontYN
      appliances
      rentIncludes
      associationAmenities
      associationFee
      associationFeeFrequency
      associationFeeIncludes
      associationYN
      accessibilityFeatures
      architecturalStyle
      attachedGarageYN
      basement
      bathroomsFull
      bathroomsHalf
      bathroomsOneQuarter
      bathroomsPartial
      bathroomsThreeQuarter
      bathroomsTotalInteger
      bedroomsTotal
      bodyType
      photos
      carportSpaces
      constructionMaterials
      cooling
      coolingYN
      directionFaces
      exteriorFeatures
      fireplaceFeatures
      fireplaceYN
      fireplacesTotal
      flooring
      foundationDetails
      garageSpaces
      heating
      heatingYN
      interiorFeatures
      levels
      livingArea
      livingAreaSource
      livingAreaUnits
      mobileLength
      mobileWidth
      newConstructionYN
      parkingFeatures
      patioAndPorchFeatures
      propertyAttachedYN
      roof
      skirt
      stories
      storiesTotal
      windowFeatures
      yearBuilt
      location {
        address {
          streetName
          city
          stateOrProvince
          postalCode
        }
        GIS {
          latitude
          longitude
          directions
        }
      }
      listing {
        publicRemarks
        closePrice
        listPrice
      }
    }
  }
`;

export const GET_SETTING = gql`
  query GET_SETTING($filter: FilterFindOneSettingInput!) {
    setting(filter: $filter) {
      _id
      image
      banner {
        title
      }
      sections {
        title
        text
        titleTranslate
        textTranslate
        image
        textImages
        additionalInfo {
          text1
          text2
          text3
        }
      }
      page
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query GET_EMPLOYEES($limit: Int) {
    employees(limit: $limit) {
      _id
      firstName
      lastName
      charge
      description
      socialNetworks {
        twitter
        instagram
        facebook
      }
      active
      photo
    }
  }
`;

export const GET_CAROUSELS = gql`
  query GET_CAROUSELS($limit: Int) {
    carousels(limit: $limit) {
      _id
      title
      titleTranslate
      descriptionTranslate
      description
      image
      url
    }
  }
`;

export const GET_PROJECTWORKS = gql`
  query GET_PROJECTWORKS($limit: Int) {
    projectWorks(limit: $limit) {
      _id
      images
      text
      textTranslate
      active
      name
      position
    }
  }
`;

export const GET_REVIEWS = gql`
  query GET_REVIEWS($limit: Int) {
    reviews(limit: $limit) {
      _id
      text
      textTranslate
      active
      fullName
    }
  }
`;
