import React from 'react';

export default function Contact() {
  return (
    <div
      className="w-full xs:h-full md:h-96 bg-cover flex flex-col items-center justify-items-center"
      style={{ backgroundImage: 'url(img/propiedades.png)' }}
      id="contact"
    >
      <div className="flex lg:w-4/5 md:w-full lg:flex-row md:flex-row xs:flex-col justify-center lg:my-auto md:my-auto font-bold text-secondary-100  xs:my-3">
        <div className="flex w-full justify-center items-center flex-col mt-4">
          <div className="mx-auto mb-4 ">
            <img src="img/US.png" alt="usImage" />
          </div>
          <div className="text-center xs:text-xs lg:text-base">
            <h2>Miami - Main Office</h2>
            <h3>55 Merrick Way, Suite 218 Coral Gables</h3>
            <h3>USA</h3>
            <p>Telephones: +1 786 376.22.22</p>
            <p>Telephones: +1 786 477.50.91</p>
          </div>
        </div>
        <div className="flex w-full justify-center items-center flex-col xs:mt-8 lg:mt-2 text-secondary-100 ">
          <div className="mx-auto mb-4">
            <img className="px-10" src="img/US.png" alt="usImage" />
          </div>
          <div className="xs:mx-12 phoneLG:mx-14 text-center xs:mb-10 md:mb-0 lg:mb-0 xs:text-xs lg:text-base">
            <h2>Orlando</h2>
            <h3>2295. Hiawassee Road, Suite 407E</h3>
            <h3>USA</h3>
            <p>Telephones: +1 407 255.08.71</p>
          </div>
        </div>
        <div className="flex w-full justify-center items-center flex-col mt-2">
          <div className="mx-auto mb-4">
            <img className="px-10" src="img/Spain.png" alt="usImage" />
          </div>
          <div className="xs:mx-auto lg:mx-1 text-center xs:mb-10 md:mb-0 lg:mb-0 xs:text-xs lg:text-base">
            <h2>Madrid</h2>
            <h3>
              Calle Velazquez número 78, 2 piso puerta derecha. Madrid 28001
            </h3>
            <p>Telephones: +34 678 327321</p>
            <p>Telephones: +34 918 269473</p>
          </div>
        </div>
      </div>
    </div>
  );
}

// Dirección: calle Velazquez número 78, 2 piso puerta derecha. Madrid 28001
// Teléfono móvil +34 678 327321
// Teléfono fijo +34918269473
// import React from 'react';

// export default function Contact() {
//   return (
//     <div
//       className="lg:h-96 xs:h-full w-full  bg-cover -mt-28 flex flex-wrap flex-col items-center justify-center "
//       style={{ backgroundImage: 'url(img/propiedades.png)' }}
//       id="contact"
//     >
//       <div className="flex lg:flex-row md:flex-row xs:flex-col justify-center my-auto font-bold text-secondary-100 lg:my-0 md:my-0 xs:my-3">
//         <div className="flex lg:flex-col md:flex-row xs:flex-row flex-wrap">
//           <div className="mx-auto mb-4">
//             <img src="img/US.png" alt="hola" />
//           </div>
//           <div className="mx-10 text-center">
//             <h2>Miami - Main Office</h2>
//             <h3>55 Merrick Way, Suite 218 Coral Gables</h3>
//             <h3>USA</h3>
//             <p>Telephones: +1 786 376.22.22 / 477.50.91</p>
//           </div>
//         </div>
//         <div className="flex lg:flex-col md:flex-row xs:flex-row flex-wrap lg:mt-0 md:mt-0 xs:mt-3">
//           <div className="mx-auto mb-4">
//             <img className="px-10" src="img/Spain.png" alt="hola" />
//           </div>
//           <div className="mx-10 text-center mb-10">
//             <h2>Orlando</h2>
//             <h3>2295. Hiawassee Road, Suite 407E</h3>
//             <h3>USA</h3>
//             <p>Telephones: +1 407 255.08.71</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
