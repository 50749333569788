import React from 'react';
import { useRouter } from 'next/router';
import useNotify from '../hooks/useNotify';
import Search from './icons/Search';
import i18n from '../lib/i18n';
import Tooltip from './Tooltips';
import EnCommon from '../public/static/locales/en/common.json';
import EsCommon from '../public/static/locales/es/common.json';

export default function SearchInput() {
  const router = useRouter();
  const notify = useNotify();
  const [searchText, setSearchText] = React.useState<string>('');

  const SearchData = (e, flag) => {
    if (e.key === 'Enter' || e.key === 'enter' || flag) {
      if (searchText.length === 0 || searchText === '') {
        notify('You must enter a text to search', 'warning');
      } else {
        router.push(`/search/${searchText}`);
      }
    }
  };
  return (
    <>
      <div className="flex justify-items-center items-center w-full relative xs:hidden md:block">
        <Tooltip
          content={
            i18n?.i18n?.language === 'en'
              ? `${EnCommon?.tooltip?.address}`
              : `${EsCommon?.tooltip?.address}`
          }
          direction="bottom"
        >
          <input
            type="text"
            className="form-input px-5 mt-12 rounded-full bg-secondary-100 text-secondary-500 w-3/5 text-lg p-1"
            placeholder={
              i18n?.i18n?.language === 'en'
                ? `${EnCommon?.placeHolders?.inputAddress}`
                : `${EsCommon?.placeHolders?.inputAddress}`
            }
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(e) => SearchData(e, false)}
          />
          <button
            type="button"
            className="absolute bg-none bg-transparent"
            onClick={(e) => SearchData(e, true)}
          >
            <Search className="h-6 w-6 mt-14 -ml-10" color="#0164AD" />
          </button>
        </Tooltip>
      </div>
      <div className="w-full relative xs:block md:hidden px-3">
        <input
          type="text"
          className="form-input mt-12 rounded-full bg-secondary-100 text-secondary-500 xs:w-full text-lg p-1"
          placeholder={
            i18n?.i18n?.language === 'en'
              ? `${EnCommon?.placeHolders?.inputAddress}`
              : `${EsCommon?.placeHolders?.inputAddress}`
          }
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => SearchData(e, false)}
        />
        <button
          type="button"
          className="absolute bg-none bg-transparent"
          onClick={(e) => SearchData(e, true)}
        >
          <Search className="h-4 w-4 mt-14 -ml-8" color="#0164AD" />
        </button>
      </div>
    </>
  );
}
