import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Card from '../Card';
import CoinIcon from '../icons/CoinIcon';
import CashIcon from '../icons/CashIcon';
import HouseIcon from '../icons/HouseIcon';
import PiggyIcon from '../icons/PiggyIcon';
import i18n from '../../lib/i18n';
import EnCommon from '../../public/static/locales/en/common.json';
import EsCommon from '../../public/static/locales/es/common.json';
import { Setting } from '../../models';

interface ServicesProps {
  settings: Setting;
}
export default function Services({ settings }: ServicesProps) {
  const cards = [
    {
      icon: <CoinIcon />,
      title:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[4]?.title}`
          : `${settings?.sections?.[4]?.titleTranslate}`,

      text:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[4]?.text}`
          : `${settings?.sections?.[4]?.text}`,
      buttonText: 'Lorem',
      path: '/sell',
    },
    {
      icon: <CashIcon />,
      title:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[5]?.title}`
          : `${settings?.sections?.[5]?.titleTranslate}`,
      text:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[5]?.text}`
          : `${settings?.sections?.[5]?.textTranslate}`,
      buttonText: 'Lorem',
      path: '/buy',
    },
    {
      icon: <HouseIcon />,
      title:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[6]?.title}`
          : `${settings?.sections?.[6]?.titleTranslate}`,
      text:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[6]?.text}`
          : `${settings?.sections?.[6]?.textTranslate}`,
      buttonText: 'Lorem',
      path: '/search',
    },
    {
      icon: <PiggyIcon />,
      title:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[7]?.title}`
          : `${settings?.sections?.[7]?.titleTranslate}`,
      title2: '',
      text:
        i18n.i18n.language === 'en'
          ? `${settings?.sections?.[7]?.text}`
          : `${settings?.sections?.[7]?.textTranslate}`,
      buttonText: 'Lorem',
      path: '/investment',
    },
  ];
  return (
    <div className="w-full flex flex-col h-full xs:mt-28 md:mt-0">
      <div className="text-center xs:mb-4 lg:mb-0">
        <h2
          className="font-bold text-3xl text-center text-primary-500 md:mb-10 mx-auto"
          id="services"
        >
          {i18n.i18n.language === 'en'
            ? EnCommon.home.services
            : EsCommon.home.services}
        </h2>
      </div>
      <div className="flex-col flex xs:lg:flex-col lg:flex-row w-full content-center">
        {cards.map((_card, _index) => (
          <div
            className="xs:w-4/5 md:w-1/3 lg:w-64 z-10 mx-auto lg:mx-2 xl:mx-auto"
            key={_index}
          >
            <Card className="shadow-lg">
              <div className="flex flex-col items-center h-96">
                <div className="bg-primary-500 rounded-full p-3">
                  {_card.icon}
                </div>
                <div>
                  <h2 className="text-black-100 text-lg xs:text-sm font-bold mt-2">
                    {_card?.title}
                  </h2>
                </div>
                <div>
                  <p className="mt-3 mb-8 text-secondary-800 text-base">
                    {_card?.text}
                  </p>
                </div>
              </div>
              <div className="mt-auto">
                <Link href={_card.path}>
                  <button
                    type="button"
                    className="text-secondary-100 bg-primary-500 rounded-full py-2 px-8"
                  >
                    {i18n.i18n.language === 'en'
                      ? EnCommon.home.cards[_index].buttonText
                      : EsCommon.home.cards[_index].buttonText}
                  </button>
                </Link>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <div
        className="bg-cover w-full flex justify-center flex-wrap mb-0 xs:-mt-72  lg:-mt-60 md:-mt-96 pb-0"
        style={{
          backgroundImage: 'url(img/buildingsbackground.svg)',
          height: '70vh',
        }}
      />
    </div>
  );
}

// import React from 'react';
// import Link from 'next/link';
// import { useRouter } from 'next/router';
// import Card from '../Card';
// import CoinIcon from '../icons/CoinIcon';
// import CashIcon from '../icons/CashIcon';
// import HouseIcon from '../icons/HouseIcon';
// import PiggyIcon from '../icons/PiggyIcon';

// export default function Services() {
//   return (
//     <div
//       className="h-screen w-full flex flex-wrap flex-col mb-0 pb-0 -mt-16"
//       style={{ backgroundImage: 'url(img/buildingsbackground.svg)' }}
//     >
//       <div className="text-center">
//         <h2 className="font-bold text-3xl text-center text-primary-500 -mt-24">
//           Services
//         </h2>
//       </div>

//       <div className="w-full grid lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-2 my-10">
//         <div className="lg:w-3/5 md:w-1/5 sm:w-1/4 xs:w-3/4 mx-4">
//           <Card className="shadow-lg">
//             <div className="flex flex-col items-center">
//               <div className="bg-primary-500 rounded-full p-3">
//                 <CoinIcon />
//               </div>
//               <div>
//                 <h2 className="text-black-100 text-lg font-bold mt-2">
//                   {' '}
//                   <span className="text-primary-400">Sell</span> a house
//                 </h2>
//               </div>
//               <div>
//                 <p className="xs:hidden md:block mt-3 mb-8 text-center">
//                   Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                   Voluptate repudiandae illum ab!
//                 </p>
//               </div>
//               <div>
//                 <Link href="/sell">
//                   <a
//                     href="#"
//                     className="text-secondary-100 bg-primary-500 rounded-full py-2 px-8"
//                   >
//                     Lorem
//                   </a>
//                 </Link>
//               </div>
//             </div>
//           </Card>
//         </div>
//         <div className="lg:w-3/5 md:w-1/5 sm:w-1/2 xs:w-3/4 mx-4">
//           <Card className="shadow-lg">
//             <div className="flex flex-col items-center">
//               <div className="bg-primary-500 rounded-full p-3">
//                 <CashIcon />
//               </div>
//               <div>
//                 <h2 className="text-black-100 text-lg font-bold mt-2">
//                   {' '}
//                   <span className="text-primary-400">Buy</span> a house
//                 </h2>
//               </div>
//               <div>
//                 <p className="xs:hidden md:block mt-3 mb-8 text-center">
//                   Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                   Voluptate repudiandae illum ab!
//                 </p>
//               </div>
//               <div>
//                 <Link href="/buy">
//                   <a
//                     href="#"
//                     className="text-secondary-100 bg-primary-500 rounded-full py-2 px-8"
//                   >
//                     Lorem
//                   </a>
//                 </Link>
//               </div>
//             </div>
//           </Card>
//         </div>
//         <div className="lg:w-3/5 md:w-1/5 sm:w-1/2 xs:w-3/4 mx-4">
//           <Card className="shadow-lg">
//             <div className="flex flex-col items-center">
//               <div className="bg-primary-500 rounded-full p-3">
//                 <HouseIcon />
//               </div>
//               <div>
//                 <h2 className="text-black-100 text-lg font-bold mt-2">
//                   {' '}
//                   <span className="text-primary-400">Rent</span> a house
//                 </h2>
//               </div>
//               <div>
//                 <p className="xs:hidden md:block mt-3 mb-8 text-center">
//                   Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                   Voluptate repudiandae illum ab!
//                 </p>
//               </div>
//               <div>
//                 <a
//                   href="#"
//                   className="text-secondary-100 bg-primary-500 rounded-full py-2 px-8"
//                 >
//                   Lorem
//                 </a>
//               </div>
//             </div>
//           </Card>
//         </div>
//         <div className="lg:w-3/5 md:w-1/5 sm:w-1/2 xs:w-3/4 mx-4">
//           <Card className="shadow-lg">
//             <div className="flex flex-col items-center">
//               <div className="bg-primary-500 rounded-full p-3">
//                 <PiggyIcon />
//               </div>
//               <div>
//                 <h2 className="text-black-100 text-lg font-bold mt-2">
//                   {' '}
//                   <span className="text-primary-400">Invest</span>
//                 </h2>
//               </div>
//               <div>
//                 <p className="xs:hidden md:block mt-3 mb-8 text-center">
//                   Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                   Voluptate repudiandae illum ab!
//                 </p>
//               </div>
//               <div>
//                 <a
//                   href="#"
//                   className="text-secondary-100 bg-primary-500 rounded-full py-2 px-8"
//                 >
//                   Lorem
//                 </a>
//               </div>
//             </div>
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// }
