import React from 'react';

interface CardProps {
  children?: React.ReactNode;
  className?: string;
}

function Card({ children, className = '' }: CardProps) {
  return (
    <div
      className={`z-10 flex flex-col flex-wrap w-full bg-gray-100 round6xl shadow mx-auto mb-8 ${className}`}
    >
      <div className="flex flex-col flex-wrap w-full bg-gray-100 round6xl shadow mx-auto text-center p-5">
        {children}
      </div>
    </div>
  );
}

export default Card;
