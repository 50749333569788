import React from 'react';
import Heading from '../Heading';
import i18n from '../../lib/i18n';

interface AlliesProps {
  title?: string;
  text?: string;
  images?: Array<string>;
}
function Allies({ title, text, images }: AlliesProps) {
  return (
    <div className="flex items-center w-full flex-col min-h-70">
      <Heading title={title} text={text} />
      <div
        className="xs:mt-6 lg:mt-0 w-4/5 h-1/4 mx-auto bg-gray-100 shadow-lg flex md:flex-row xs:flex-col justify-center items-center"
        style={{ borderRadius: '2.5rem' }}
      >
        {images?.map((image, _index) => (
          <div className="w-1/4 mx-auto" key={_index}>
            <img
              className="object-contain mx-auto w-32 h-32 rounded-xl"
              src={`${image ?? '/img/main-logo-black.png'}`}
              alt="logo"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default i18n.withTranslation()(Allies);
