/* eslint-disable no-restricted-globals */
import React from 'react';
import SearchInput from '../SearchInput';
import i18n from '../../lib/i18n';
import { Setting } from '../../models';

interface WelcomeProps {
  setting?: Setting;
}
export default function Welcome({ setting }: WelcomeProps) {
  return (
    <div
      className="xs:h-96 lg:h-screen lg:w-full md:w-full sm:w-full xs:w-full justify-items-center items-center"
      id="welcomeImage"
    >
      <div
        className="h-4/5 bg-cover flex justify-center items-center bg-bottom"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(26, 32, 44, 0.5), rgba(26, 32, 44, 0.5)), url(${
            setting?.image?.[0] ?? 'img/Rectangle2.png'
          })`,
        }}
      >
        <div className="flex flex-col text-center w-full justify-items-center items-center">
          <p className="font-bold lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl text-secondary-100">
            {i18n?.i18n?.language === 'en'
              ? setting?.banner?.title
              : setting?.banner?.titleTranslate}
          </p>
          {/* <input
            type="text"
            className="form-input px-5 lg:mt-20 md:mt-12 xs:mt-12 rounded-full bg-secondary-100 text-secondary-500 lg:w-3/5 md:w-4/5 xs:w-4/5 text-lg p-1 mx-auto"
            placeholder="Enter an address, neighborhood, city or ZIP code"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(e) => SearchData(e)}
          /> */}
          <SearchInput />
        </div>
      </div>
      <div className="bg-primary-500 w-full h-4" />
    </div>
  );
}
