import React from 'react';

interface HeadingProps {
  className?: string;
  title?: string;
  text?: string;
}

function Heading({ className = '', title, text = '' }: HeadingProps) {
  return (
    <div
      className={`flex items-center justify-center xs:justify-center md:justify-center lg:justify-center w-full h-1/2 ${className}`}
    >
      <div className="text-center lg:w-1/2 md:w-1/2 xs:w-4/5 xs:text-center">
        <h2 className="text-primary-500  font-bold xs:text-3xl sm:text-3xl md:text-4xl lg:text-4xl  mb-4">
          {title}
        </h2>
        <div className="bg-primary-500 w-1/5 h-3 mx-auto mt-2" />
        {text ? (
          <p className="text-secondary-800 text-base mt-8">{text}</p>
        ) : null}
      </div>
    </div>
  );
}

export default Heading;
