import React from 'react';

function HouseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.5 26l4.333-4.333m0 0L26 6.5l15.167 15.167m-30.334 0v21.666c0 .575.229 1.126.635 1.532m-.635-23.198l.635 23.198m29.699-23.198L45.5 26m-4.333-4.333v21.666c0 .575-.229 1.126-.635 1.532m.635-23.198l-.635 23.198m-29.064 0c.406.407.957.635 1.532.635h6.5m-8.032-.635l8.032.635m0 0a2.167 2.167 0 002.167-2.167v-8.666a2.166 2.166 0 012.166-2.167h4.334a2.167 2.167 0 012.166 2.167v8.666A2.167 2.167 0 0032.5 45.5m-13 0h13m8.032-.635A2.167 2.167 0 0139 45.5h-6.5m8.032-.635L32.5 45.5"
      />
    </svg>
  );
}

export default HouseIcon;
