import React from 'react';

function CoinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M26 17.333c-3.59 0-6.5 1.94-6.5 4.334C19.5 24.06 22.41 26 26 26s6.5 1.94 6.5 4.333c0 2.395-2.91 4.334-6.5 4.334V17.333zm0 0c2.405 0 4.507.871 5.631 2.167L26 17.333zm0 0v-2.166 2.166zm0 0v17.334-17.334zm0 17.334v2.166-2.166zm0 0c-2.405 0-4.507-.871-5.631-2.167L26 34.667zM45.5 26a19.5 19.5 0 11-39.002 0A19.5 19.5 0 0145.5 26z"
      />
    </svg>
  );
}

export default CoinIcon;
