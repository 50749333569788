import React from 'react';
import TeamWorkIcon from '../icons/TeamWorkIcon';
import i18n from '../../lib/i18n';
import EnCommon from '../../public/static/locales/en/common.json';
import EsCommon from '../../public/static/locales/es/common.json';

interface OurTeamProps {
  title?: string;
  text?: string;
}
function OurTeam({ title, text }: OurTeamProps) {
  return (
    <div className="flex lg:flex-row xs:flex-col min-h-70 mt-10 justify-between xs:mx-auto md:mx-0">
      <div className="flex lg:w-1/2 xs:w-full">
        <div className="w-full lg:relative xs:static">
          <div
            className="w-1/2 bg-gray-200 rounded-full lg:p-40 xl:p-52 bg-opacity-25 md:absolute md:inline-block xs:hidden right-2"
            style={{ left: '30px' }}
          />
          <div className="md:absolute xs:static w-full xs:mx-auto lg:ml-5">
            <h2 className="text-primary-500 xs:text-2xl lg:text-3xl font-bold mb-4 text-left xs:ml-0 xs:mt-0 lg:mt-24 xl:ml-40 lg:ml-28 xs:text-center lg:text-justify xs:mx-auto">
              {title}
            </h2>
            <p className="xs:text-sm lg:text-base lg:ml-28 xl:ml-40 xs:w-4/5 lg:w-3/5 md:w-96 xs:text-center lg:text-justify xs:mx-auto text-secondary-800 text-base">
              {text}
            </p>
          </div>
        </div>
      </div>
      <div className="flex lg:w-1/2 xs:w-full">
        <div className="mx-auto mt-0 xs:mt-8 sm:mt-16 md:mt-48 lg:mt-0">
          <TeamWorkIcon className="xs:w-64 md:w-72 xl:w-96 lg:mt-8 xs:mt-1" />
        </div>
      </div>
    </div>
  );
}

export default i18n.withTranslation()(OurTeam);
