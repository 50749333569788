import React from 'react';

interface SearchProps {
  className?: string;
  color?: string;
}
function Search({ className, color }: SearchProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 66 66"
      className={`${className}`}
    >
      <path
        stroke={`${color}`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M63 63L43 43l20 20zM49.667 26.333a23.333 23.333 0 11-46.667 0 23.333 23.333 0 0146.667 0z"
      />
    </svg>
  );
}

export default Search;
