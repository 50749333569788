import React from 'react';
import { useQuery } from '@apollo/client';
import i18n from '../lib/i18n';
import Navbar from '../components/Navbar';
import Welcome from '../components/home/Welcome';
import Numbers from '../components/home/Numbers';
import Ourteam from '../components/home/Ourteam';
import Allies from '../components/home/Allies';
import Services from '../components/home/Services';
import Contact from '../components/home/Contact';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import EnCommon from '../public/static/locales/en/common.json';
import EsCommon from '../public/static/locales/es/common.json';
import { GET_SETTING } from '../graphql/queries';
import { Setting } from '../models';

const translate = i18n;

function HomePage({ t }) {
  const { data, loading, error } = useQuery<{ setting?: Setting }>(
    GET_SETTING,
    {
      variables: {
        filter: {
          page: 'Home',
        },
        fetchPolicy: 'network-only',
      },
    }
  );
  return (
    <>
      <section className="w-full h-full min-w-full flex flex-col flex-wrap bg-secondary-100">
        <header>
          <Navbar />
        </header>
        <main className="min-h-screen w-full flex flex-col flex-wrap">
          <Welcome setting={data?.setting} />
          <Heading
            title={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[0]?.title}`
                : `${data?.setting?.sections?.[0]?.titleTranslate}`
            }
            text={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[0]?.text}`
                : `${data?.setting?.sections?.[0]?.textTranslate}`
            }
            className="mb-14"
          />
          <Numbers
            title={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[1]?.title}`
                : `${data?.setting?.sections?.[1]?.titleTranslate}`
            }
            text={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[1].text}`
                : `${data?.setting?.sections?.[1].textTranslate}`
            }
            additionalInfo={data?.setting?.sections?.[1].additionalInfo}
            image={data?.setting?.sections?.[1].image?.[0]}
          />
          <Ourteam
            title={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[2]?.title}`
                : `${data?.setting?.sections?.[2]?.titleTranslate}`
            }
            text={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[2]?.text}`
                : `${data?.setting?.sections?.[2]?.textTranslate}`
            }
          />
          <Allies
            title={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[3]?.title}`
                : `${data?.setting?.sections?.[3]?.titleTranslate}`
            }
            text={
              translate.i18n.language === 'en'
                ? `${data?.setting?.sections?.[3]?.text}`
                : `${data?.setting?.sections?.[3]?.textTranslate}`
            }
            images={data?.setting?.sections?.[3]?.image}
          />
          <Services settings={data?.setting} />
          <Contact />
        </main>
        <Footer />
      </section>
    </>
  );
}

export default translate.withTranslation()(HomePage);
