import React from 'react';

function PiggyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M23.4 15.844a17.14 17.14 0 00-5.769 1.787c-6.418 3.413-7.475 8.938-7.475 13.244 0 4.306 1.869 10.644 8.775 16.25h5.931v-3.25l7.232.406a16.326 16.326 0 001.868 2.844h5.444V42.25a38.917 38.917 0 004.875-4.063c2.357.244 4.794-1.624 5.688-7.068 0-.813-.407-1.056-.813-1.056a5.525 5.525 0 01-2.437-.813c-.325-.406-1.3-3.819-3.088-6.5a9.506 9.506 0 013.331-4.225c-2.275-1.137-4.63-1.95-9.262-1.462-1.219-.244-3.25-.732-5.2-1.057"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M28.031 17.875a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM22.913 20.394a16.332 16.332 0 0110.318-.081M10.156 30.874C7.8 30.631 2.031 29.738 2.031 32.5c0 2.763 1.544 2.763 3.088 2.112 1.543-.65 3.494-4.468-3.088-6.987l8.125 3.25zM39.406 28.438a.813.813 0 100-1.626.813.813 0 000 1.625z"
      />
    </svg>
  );
}

export default PiggyIcon;
