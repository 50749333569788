import React from 'react';
import { Settings } from 'http2';

interface NumbersProps {
  title?: string;
  text?: string;
  additionalInfo?: {
    text1?: string;
    text2?: string;
    text3?: string;
  };
  image?: string;
}
export default function Numbers({
  title,
  text,
  additionalInfo,
  image,
}: NumbersProps) {
  return (
    <div
      className="flex flex-col flex-wrap xs:min-h-full mx-auto items-center mt-10 lg:w-3/4"
      id="about"
      style={{ height: '55vh' }}
    >
      <div className="w-4/5 h-3/5 mx-auto bg-no-repeat flex flex-col">
        <div className="flex flex-row w-full h-4/5 max-w-full max-h-full sectionBackground rounded-lg">
          <img
            src="https://res.cloudinary.com/pomelos/image/upload/v1626882468/pomelos_to_go/qd0r4fnl2nclq3sss6y0.jpg"
            alt="sectionBackground"
            className="h-auto w-full bg-contain rounded-lg max-w-full max-h-full"
          />
        </div>
        <div
          className="text-center align-middle mx-5 xs:-mt-48 lg:-mt-52 lg:mb-14 md:mb-20 z-40 margin-top-text"
          style={{ marginTop: '-10rem' }}
        >
          <p className="font-bold lg:text-xl xs:text-base text-secondary-100">
            {title}
          </p>
          <p className="md:text-base xs:text-base text-secondary-100 align-middle mb-10 mt-5">
            {text}
          </p>
        </div>
        <div className="flex flex-row shadow-lg w-4/5 h-2/5 rounded-lg bg-gray-100 mx-auto xs:hidden md:flex z-40 mt-auto margin-top-box">
          <div className="flex flex-col text-center m-auto">
            <h2 className="text-primary-500 font-bold lg:mt-5 md:text-base lg:text-2xl">
              {additionalInfo?.text1 ?? '+500'}
            </h2>
            <p className="md:-mt-1 xs:mx-9 xs:my-2 md:text-sm lg:text-base text-secondary-500">
              Investors
            </p>
          </div>
          <div className="flex flex-col text-center m-auto">
            <h2 className="text-primary-500 font-bold lg:mt-5 md:text-base lg:text-2xl">
              {additionalInfo?.text2 ?? '+160.000'}
            </h2>
            <p className="md:-mt-1 xs:my-2 text-secondary-500 md:text-sm lg:text-base">
              Sales per year
            </p>
          </div>
          <div className="flex flex-col text-center m-auto">
            <h2 className="text-primary-500 font-bold lg:mt-5 md:text-base lg:text-2xl">
              {additionalInfo?.text3 ?? '+800'}
            </h2>
            <p className="md:-mt-1 xs:my-2 md:text-sm lg:text-base text-secondary-500">
              Rent per month
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row mx-auto xs:block md:hidden">
        <div className="md:w-1/3 flex md:flex-col xs:flex-row flex-wrap justify-center items-center  md:border-r xs:border-b md:border-b-0 border-gray-400">
          <h2 className="text-primary-500 lg:text-3xl md:text-3xl sm:text-2xl xs:text-base font-bold lg:mt-5">
            {additionalInfo?.text1 ?? '+500'}
          </h2>
          <p className="md:-mt-1 xs:mx-9 xs:my-2  text-secondary-500 lg:text-lg md:text-base sm:text-sm xs:text-base">
            Investors
          </p>
        </div>
        <div className="md:w-1/3 flex md:flex-col xs:flex-row flex-wrap justify-center items-center md:border-r md:border-b-0 xs:border-b xs border-gray-400">
          <h2 className="text-primary-500 text-3xl font-bold lg:text-3xl md:text-3xl sm:text-2xl xs:text-base lg:mt-5">
            {additionalInfo?.text2 ?? '+160.000'}
          </h2>
          <p className="md:-mt-1 xs:mx-2 xs:my-2  text-secondary-500 lg:text-base md:text-sm sm:text-sm xs:text-base">
            Sales per year
          </p>
        </div>
        <div className="md:w-1/3 flex md:flex-col xs:flex-row flex-wrap justify-center items-center">
          <h2 className="text-primary-500 text-3xl font-bold lg:text-3xl md:text-3xl sm:text-2xl xs:text-base lg:mt-5">
            {additionalInfo?.text3 ?? '+800'}
          </h2>
          <p className="md:-mt-1 xs:mx-4  xs:my-2  text-secondary-500 lg:text-base md:text-sm sm:text-sm xs:text-base">
            Rents per month
          </p>
        </div>
      </div>
    </div>
  );
}
