import React from 'react';

interface TooltipProps {
  children: React.ReactNode;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  content: string;
  delay?: number;
}

const Tooltip = ({
  children,
  content,
  direction = 'top',
  delay = 400,
}: TooltipProps) => {
  let timeout;
  const [active, setActive] = React.useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper w-full"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <div
          className={`Tooltip-Tip ${direction} bg-secondary-700 text-white text-lg py-2 text-center px-3 flex flex-row flex-wrap h-auto`}
        >
          {/* Content */}
          <p className="text-center text-white"> {content}</p>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
