import React from 'react';

function CashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M36.833 19.5v-4.333a4.333 4.333 0 00-4.333-4.334H10.833A4.333 4.333 0 006.5 15.167v13a4.333 4.333 0 004.333 4.333h4.334l.08-9.5 3.81-3.477 17.776-.023zM19.5 41.167h21.667a4.333 4.333 0 004.333-4.334v-13a4.333 4.333 0 00-4.333-4.333H19.5a4.333 4.333 0 00-4.333 4.333v13a4.333 4.333 0 004.333 4.334zm15.167-10.834a4.334 4.334 0 11-8.668 0 4.334 4.334 0 018.668 0z"
      />
    </svg>
  );
}

export default CashIcon;
